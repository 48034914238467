@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500,700|Material+Icons");
@import url("https://unpkg.com/vuetify@0.8.10/dist/vuetify.min.css");

html {
  font-size:16px;
}

[v-cloak] {
  display:none;
}

.modal-spinner-container {
  display: none;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
}

.modal-spinner-content {
  top: 50%;
  left: 50%;
  display: block;
  position: absolute;
  margin-left: -40px;
  margin-top: -40px;
  width:80px;
  height:80px;
  padding: 20px;
  background: rgba(0, 0, 0, 0.1);
  border-radius:10px;
}

.modal-spinner-content img {
  position:absolute;
  left:20px;
  top:20px;
  width:40px;
  height:40px;
}

.modal-spinner-content:active,
.modal-spinner-content:focus {
  box-shadow: none;
  outline: 0 none;
  border:0;
}

.modal {
  overflow:auto;
}

.toolbar {
  margin-bottom:64px;

  .toolbar__logo img {
    display:block;
    position:absolute;
    top:0;
  }
}

.crantek-toolbar-items {
  color:white;

  a:hover {
    background:initial;
    text-decoration: underline;
  }

  .active {
    text-transform: uppercase;
    font-weight: bold;
  }
}

.modal__container {
  display:block;
}

.input-group textarea {
  margin-top:1rem;
  height:4.5rem;
}

.print-only {
  display: none;
}

@media print {
  .no-print {
    display: none;
  }

  .print-2-col {
    display:table-row;

    > * {
      display:table-cell;
      width:50%;
      vertical-align: top;
    }
  }

  .print-only {
    display: block;
  }

  .card, table, tr, td, th, tbody, thead, tfoot {
    page-break-inside: avoid !important;
  }
}